import Main from "./Main";
import { SensorCardBoxes } from "./SensorPage";
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
//Grid.js
import { Grid, html } from "gridjs";
import "gridjs/dist/theme/mermaid.css";
import axios from "axios";
import ModalAlarmCard from './ModalAlarmCard';

export function AlarmCardBoxes({ p }) {
  const [countWarning, setCountWarning] = useState(0);
  const [countCritical, setCountCritical] = useState(0);
  const [refresh, setRefresh] = useState(false)
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("token");
  const myRef = useRef({})
  useEffect(() => {
    let params;
    if (p != undefined)
      params = `?process=${p}`;
    else
      params = "";
    //count all sensors
    axios.get(`${baseURL}/countwarning` + params + (params != "" ? "&" : "?") + 'search=&limit=25&offset=0',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        //console.log(response.data.sensors)
        setCountWarning(response.data.count)
      })
      .catch(error => {
        console.log("Cannot get count warning:", error)
      })
      .finally(() => { });
    //count live sensors
    axios.get(`${baseURL}/countcritical` + params + (params != "" ? "&" : "?") + 'search=&limit=25&offset=0',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        //console.log(response.data.sensors)
        setCountCritical(response.data.count)
      })
      .catch(error => {
        console.log("Cannot get count critical:", error)
      })
      .finally(() => { });
    //refresh
    /* const intervalID=setInterval(()=>{
        console.log("refresh!:",new Date(Date.now()).toLocaleString())
        setRefresh(!refresh)
    }, 900000);
    return  () => {
        clearInterval(intervalID); // Clean up the interval on component unmount
    }; */

  }, [p]);
  return (<>
    <ModalAlarmCard p={p} myRef={myRef} />
    <div onClick={() => myRef.current.openModal("countwarning")} className="card">
      <div className="iconBx" id="sensor">
        <ion-icon id="critical2" name="alert-circle" style={{ color: '#e9b10a', fontSize: '2rem' }}></ion-icon>
      </div>
      <div>
        <div className="numbers">{countWarning}</div>
        <div className="cardName">Warning</div>
      </div>

    </div>
    <div onClick={() => myRef.current.openModal("countcritical")} className="card">
      <div className="iconBx" id="warning2">
        <ion-icon name="alert-circle" style={{ color: '#f00', fontSize: '2rem' }}></ion-icon>
      </div>
      <div>
        <div className="numbers">{countCritical}</div>
        <div className="cardName">Critical</div>
      </div>
    </div>
  </>
  );
}

function AlarmTable() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("token");
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  const grid = new Grid({
    resizable: true,
    sort: true,
    search: true,
    search: {
      server: {
        url: (prev, keyword) => `${prev}?search=${keyword}`
      }
    },
    pagination: {
      limit: 25,
      server: {
        url: (prev, page, limit) => {
          //console.log("Fetching data from APIs")
          if (prev.includes("?search=")) {
            return `${prev}&limit=${limit}&offset=${page * limit}`
          }
          else
            return `${prev}?limit=${limit}&offset=${page * limit}`
        }
      }
    },
    autoWidth: true,
    columns: [{
      name: 'Sensor time',
      formatter: (cell) => 1 ? html(`${cell.slice(0, -3)}`) : html(`<i>${cell}</i>`)
    },
    {
      name: 'Type',
      formatter: (_, row) => {
        let x = row.cells[4].data != "n/a" ? parseFloat(row.cells[4].data) : undefined;
        let y = row.cells[5].data != "n/a" ? parseFloat(row.cells[5].data) : undefined;
        let z = row.cells[6].data != "n/a" ? parseFloat(row.cells[6].data) : undefined;
        let temp = row.cells[7].data != "n/a" ? parseFloat(row.cells[7].data) : undefined;
        let warning_x = row.cells[8].data != "n/a" ? parseFloat(row.cells[8].data) : undefined;
        let warning_y = row.cells[9].data != "n/a" ? parseFloat(row.cells[9].data) : undefined;
        let warning_z = row.cells[10].data != "n/a" ? parseFloat(row.cells[10].data) : undefined;
        let warning_temp = row.cells[11].data != "n/a" ? parseFloat(row.cells[11].data) : undefined;
        let critical_x = row.cells[12].data != "n/a" ? parseFloat(row.cells[12].data) : undefined;
        let critical_y = row.cells[13].data != "n/a" ? parseFloat(row.cells[13].data) : undefined;
        let critical_z = row.cells[14].data != "n/a" ? parseFloat(row.cells[14].data) : undefined;
        let critical_temp = row.cells[15].data != "n/a" ? parseFloat(row.cells[15].data) : undefined;
        if (x >= critical_x || y >= critical_y || z >= critical_z || temp >= critical_temp)
          return html(`<ion-icon name="alert-circle" style='color:#f00;font-size:25px;text-align:center'></ion-icon>`)
        else if (x >= warning_x || y >= warning_y || z >= warning_z || temp >= warning_temp)
          return html(`<ion-icon name="alert-circle" style='color:#e9b10a;font-size:25px;text-align:center'></ion-icon>`)

      }
    }, 'Sensor id', 'Name', 'x', 'y', 'z', 'temp', 'warning x', 'warning y', ' warning z', 'warning temp', 'critical x', 'critical y', ' critical z', 'critical temp',
    {
      name: 'description',
      formatter: (_, row) => {
        let x = row.cells[4].data != "n/a" ? parseFloat(row.cells[4].data) : undefined;
        let y = row.cells[5].data != "n/a" ? parseFloat(row.cells[5].data) : undefined;
        let z = row.cells[6].data != "n/a" ? parseFloat(row.cells[6].data) : undefined;
        let temp = row.cells[7].data != "n/a" ? parseFloat(row.cells[7].data) : undefined;
        let warning_x = row.cells[8].data != "n/a" ? parseFloat(row.cells[8].data) : undefined;
        let warning_y = row.cells[9].data != "n/a" ? parseFloat(row.cells[9].data) : undefined;
        let warning_z = row.cells[10].data != "n/a" ? parseFloat(row.cells[10].data) : undefined;
        let warning_temp = row.cells[11].data != "n/a" ? parseFloat(row.cells[11].data) : undefined;
        let critical_x = row.cells[12].data != "n/a" ? parseFloat(row.cells[12].data) : undefined;
        let critical_y = row.cells[13].data != "n/a" ? parseFloat(row.cells[13].data) : undefined;
        let critical_z = row.cells[14].data != "n/a" ? parseFloat(row.cells[14].data) : undefined;
        let critical_temp = row.cells[15].data != "n/a" ? parseFloat(row.cells[15].data) : undefined;
        let des = ""
        if (x >= critical_x) des += "critical_x,"
        if (y >= critical_y) des += "critical_y,"
        if (z >= critical_z) des += "critical_z,"
        if (temp >= critical_temp) des += "critical_temp,"
        if (x >= warning_x) des += "warning_x,"
        if (y >= warning_y) des += "warning_y,"
        if (z >= warning_z) des += "warning_z,"
        if (temp >= warning_temp) des += "warning_temp,"

        des = des.slice(0, -1)
        return html(`${des}`)

      }
    }],
    style: {
      table: {
        'white-space': 'nowrap',
      },
      td: {
        'background-color': 'transparent'
      },
    },
    server: {
      url: `${baseURL}/alarms`,
      data: (opts) => {
        return new Promise((resolve, reject) => {
          axios.get(opts.url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(response => {
            //console.log(response);
            resolve({
              data: response.data.results.map(sensor => [sensor.sensor_time,
                "type",
              sensor.sensor_id,
              sensor.name,
              sensor.x ? sensor.x.toFixed(2) : "n/a",
              sensor.y ? sensor.y.toFixed(2) : "n/a",
              sensor.z ? sensor.z.toFixed(2) : "n/a",
              sensor.temp ? sensor.temp.toFixed(2) : "n/a",
              sensor.warning_x ? sensor.warning_x.toFixed(2) : "n/a",
              sensor.warning_y ? sensor.warning_y.toFixed(2) : "n/a",
              sensor.warning_z ? sensor.warning_z.toFixed(2) : "n/a",
              sensor.warning_temp ? sensor.warning_temp.toFixed(2) : "n/a",
              sensor.critical_x ? sensor.critical_x.toFixed(2) : "n/a",
              sensor.critical_y ? sensor.critical_y.toFixed(2) : "n/a",
              sensor.critical_z ? sensor.critical_z.toFixed(2) : "n/a",
              sensor.critical_temp ? sensor.critical_temp.toFixed(2) : "n/a",
                "description"]),
              total: response.data.count,
            });
          }).catch(error => {
            reject(error);
          });
        });
      },
    },
    className: {
      tr: 'gridjs-tr-class',
    }
  });
  grid.on('rowClick', (...args) => { onSensorRowClick(args[1]._cells[2].data) });
  const onSensorRowClick = (sensor_id) => {
    navigate('/sensors/' + sensor_id + '/showAlarm');
  };

  useEffect(() => {
    grid.render(wrapperRef.current);
    const intervalID = setInterval(() => {
      wrapperRef.current.replaceChildren();
      console.log("refresh!")
      grid.forceRender(wrapperRef.current);
    }, 900000);
    return () => {
      clearInterval(intervalID); // Clean up the interval on component unmount
    };
  }, [])
  return (<div className="ml-5" ref={wrapperRef} />)
}
function AlarmPage() {


  //const navigate= useNavigate();
  return (
    <Main menuText={"Alarms"}>
      <AlarmTable />
    </Main>
  );
}

export default AlarmPage;