import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";
import axios from 'axios';
import { PieChart, Pie, Cell } from 'recharts';

export default function ProcessCardBoxes({ p }) {
    const [mechindex, setMechIndex] = useState({});
    const [mechpie, setMechPie] = useState([])
    const [tempindex, setTempIndex] = useState({});
    const [temppie, setTempPie] = useState([]);
    const [wocritical, setWoCritical] = useState({});
    const [ma, setMa] = useState({})
    const [pname, setPname] = useState([]);
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(true);
    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = sessionStorage.getItem("token");

    const pname_ref = useRef(null);
    const navigate = useNavigate();

    function readCookie(name) {
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split('=');
            if (cookieName === name) {
                return decodeURIComponent(cookieValue);
            }
        }
        return null; // Cookie not found
    }
    function createCookie(name, value) {
        const today = new Date();
        const expire = new Date(today.getTime() + 3600000); // every hours
        document.cookie = `${name}=${encodeURIComponent(value)}; path=/; expires=${expire.toUTCString()}`;
    }

    useEffect(() => {

        //count all sensors
        let params;
        if (p != undefined)
            params = `?process=${p}`;
        else
            params = "";

        axios.get(`${baseURL}/mech_index` + params,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                //console.log(response.data.sensors)
                let normal = response.data.count - response.data.critical - response.data.warning;
                const data = [
                    { name: 'normal', value: normal, fill: '#02c39a' },
                    { name: 'warning', value: response.data.warning, fill: '#f6aa1c' },
                    { name: 'critical', value: response.data.critical, fill: '#dc2f02' },
                ];
                setMechPie(data)
                setMechIndex(response.data)
            })
            .catch(error => {
                console.log("Cannot get mechanical index:", error)
            })
            .finally(() => { });
        //calculate temp index
        axios.get(`${baseURL}/temp_index` + params,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                //console.log(response.data.sensors)
                let normal = response.data.count - response.data.critical - response.data.warning;
                const data = [
                    { name: 'normal', value: normal, fill: '#02c39a' },
                    { name: 'warning', value: response.data.warning, fill: '#f6aa1c' },
                    { name: 'critical', value: response.data.critical, fill: '#dc2f02' },
                ];
                setTempPie(data)
                setTempIndex(response.data)
            })
            .catch(error => {
                console.log("Cannot get temp index:", error)
            })
            .finally(() => { });
        //calculate duration of operation w/o critical
        axios.get(`${baseURL}/wocritical` + params,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                //console.log(response.data.sensors)
                setWoCritical(response.data)
            })
            .catch(error => {
                console.log("Cannot get wo critical:", error)
            })
            .finally(() => { });

        axios.get(`${baseURL}/process`,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setPname(response.data.processes);
            }).finally(() => { });
        /* get machine availability */
        setLoading(true);
        //alert(readCookie("ma"+(p!=undefined?p:'')))
        if (readCookie("ma" + (p ? p : '')) == null) {
            axios.get(`${baseURL}/ma` + params,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(response => {
                    //console.log(response.data.sensors)

                    setMa(response.data.ma)
                    createCookie("ma" + (p != undefined ? p : ''), response.data.ma)
                })
                .catch(error => {
                    console.log("Cannot get ma using default value:", error)
                    setMa({ ma: 100 })
                })
                .finally(() => { setLoading(false); });
        } else {
            setMa(parseFloat(readCookie("ma" + (p != undefined ? p : ''))));
            setLoading(false);
        }
        //refresh
        /*  const intervalID=setInterval(()=>{
             console.log("refresh!:",new Date(Date.now()).toLocaleString())
             setRefresh(!refresh)
         }, 900000);
         return  () => {
             clearInterval(intervalID); // Clean up the interval on component unmount
         }; */

    }, [p])
    let unique_pname = pname.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.process_name === value.process_name
        ))
    )
    const pnameList = unique_pname.map(p => {
        return (<option key={p.process_name} value={p.process_name}>{p.process_name}</option>);
    })

    return (<>
        <div className="card">
            <div className="iconBx" id="sensor">
                <ion-icon name="pricetag-outline"></ion-icon>
            </div>
            <div>
                <div className="cardName">Process Area:</div>
                <select style={{ "width": "120px" }} className="relative border border-gray-400 h-7" ref={pname_ref} name="process-name" id="process-name" value={p ? p : ""} onChange={() => { navigate('/' + pname_ref.current.value) }}>
                    <option value="">All processes</option>
                    {pnameList}
                </select>

            </div>
        </div>
        <div className="card">
            <div className="iconBx" id="sensor">
                <ion-icon name="cog"></ion-icon>
            </div>
            <div>
                {loading ?
                    <div>
                        <BeatLoader
                            color={"#ffffff"}
                            loading={loading}
                            size={10}
                            aria-label="Loading Spinner"
                            data-testid="loader" />
                    </div> :
                    <div className="numbers">{ma ? ma.toFixed(0) : 100}%</div>
                }
                <div className="cardName">Mechanical Availability</div>
            </div>
        </div>
        <div className="card">
            <div className="iconBx" id="sensor">
                <PieChart width={80} height={80}>
                    <Pie data={mechpie} nameKey="name" dataKey="value" fill="#76c893" />
                </PieChart>
            </div>
            <div>
                <div className="numbers">{mechindex.mechanical_index_percent ? mechindex.mechanical_index_percent.toFixed(0) : 0}%</div>
                <div className="cardName">Mechanical Index</div>
            </div>
        </div>
        <div className="card">
            <div className="iconBx" id="sensor">
                <PieChart width={80} height={80}>
                    <Pie data={temppie} nameKey="name" dataKey="value" fill="#76c893" />
                </PieChart>
                {/*<ion-icon name="thermometer-outline"></ion-icon>*/}
            </div>
            <div>
                <div className="numbers">{tempindex.temp_index_percent ? tempindex.temp_index_percent.toFixed(0) : 0}%</div>
                <div className="cardName">Temperature Index</div>
            </div>

        </div>
        <div className="card">
            <div className="iconBx" id="sensor">
                <ion-icon name="shield-checkmark-outline"></ion-icon>
            </div>
            <div>
                <div className="numbers">{wocritical.days}d:{wocritical.hours}h</div>
                <div className="cardName">Operation W/O Critical Alarms</div>
            </div>
        </div>
        {/*<div className="card">
                        <div className="iconBx" id="critical">
                            <ion-icon name="warning"></ion-icon>
                        </div>
                        <div>
                            <div className="numbers">{countNotWorkSensor}</div>
                            <div className="cardName">Not working</div>
                        </div>
                        
                    </div>*/}
    </>
    );
}