import { useNavigate } from 'react-router-dom';
import Main from './Main';
import SensorTable from './SensorTable';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ModalSensorCard from './ModalSensorCard';
import { useRef } from 'react';

export function SensorCardBoxes({ p }) {
    const myRef = useRef({})
    const [countsensor, setCountSensor] = useState(0);
    const [countNormalSensor, setCountNormalSensor] = useState(0);
    const [countAbnormalSensor, setCountAbnormalSensor] = useState(0);
    const [countNotWorkSensor, setCountNotWorkSensor] = useState(0);
    const [refresh, setRefresh] = useState(false)
    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = sessionStorage.getItem("token");

    useEffect(() => {
        //count all sensors
        let params;
        if (p != undefined)
            params = `?process=${p}`;
        else
            params = "";
        axios.get(`${baseURL}/countsensors` + params + (params != "" ? "&" : "?") + 'search=&limit=25&offset=0',
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                //console.log(response.data.sensors)
                setCountSensor(response.data.count)
            })
            .catch(error => {
                console.log("Cannot get count sensor:", error)
            })
            .finally(() => { });
        //count live sensors
        axios.get(`${baseURL}/countnormalsensors` + params + (params != "" ? "&" : "?") + 'search=&limit=25&offset=0',
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                //console.log(response.data.sensors)
                setCountNormalSensor(response.data.count)
            })
            .catch(error => {
                console.log("Cannot get count normal sensors:", error)
            })
            .finally(() => { });
        //count unreachable sensors
        axios.get(`${baseURL}/countabnormalsensors` + params + (params != "" ? "&" : "?") + 'search=&limit=25&offset=0',
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                //console.log(response.data.sensors)
                setCountAbnormalSensor(response.data.count)
            })
            .catch(error => {
                console.log("Cannot get count abnormal sensors:", error)
            })
            .finally(() => { });
        //count not working sensors
        axios.get(`${baseURL}/countnotworksensors` + params + (params != "" ? "&" : "?") + 'search=&limit=25&offset=0',
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                //console.log(response.data.sensors)
                setCountNotWorkSensor(response.data.count)
            })
            .catch(error => {
                console.log("Cannot get count not working sensors:", error)
            })
            .finally(() => { });
        //refresh
        /* const intervalID=setInterval(()=>{
            console.log("refresh!:",new Date(Date.now()).toLocaleString())
            setRefresh(!refresh)
        }, 900000);
        return  () => {
            clearInterval(intervalID); // Clean up the interval on component unmount
        }; */

    }, [p])

    return (<>
        <ModalSensorCard p={p} myRef={myRef} />
        <div onClick={() => myRef.current.openModal("countsensors")} className="card">
            <div className="iconBx" id="sensor">
                <ion-icon name="pulse"></ion-icon>
            </div>
            <div>
                <div className="numbers">{countsensor}</div>
                <div className="cardName">Sensors</div>
            </div>

        </div>
        <div onClick={() => myRef.current.openModal("countnormalsensors")} className="card">
            <div className="iconBx" id="normal">
                <ion-icon name="happy"></ion-icon>
            </div>
            <div>
                <div className="numbers">{countNormalSensor}</div>
                <div className="cardName">Online</div>
            </div>

        </div>
        <div onClick={() => myRef.current.openModal("countabnormalsensors")} className="card">
            <div className="iconBx" id="warning">
                <ion-icon name="sad"></ion-icon>
            </div>
            <div>
                <div className="numbers">{countAbnormalSensor}</div>
                <div className="cardName">Unreachable</div>
            </div>
        </div>
        <div onClick={() => myRef.current.openModal("countnotworksensors")} className="card">
            <div className="iconBx" id="critical">
                <ion-icon name="warning"></ion-icon>
            </div>
            <div>
                <div className="numbers">{countNotWorkSensor}</div>
                <div className="cardName">Not working</div>
            </div>

        </div>
    </>
    );
}

function SensorPage() {
    const navigate = useNavigate();
    return (
        <Main menuText={"Sensors"}>
            <button type="submit" onClick={() => navigate('/sensors/add')}
                className="ml-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Add Sensor
            </button>
            <SensorTable />
        </Main>
    );
}

export default SensorPage;